/*
 * @Description:
 * @Author: warren
 * @LastEditors: Warren
 * @Date: 2023-10-24 09:14:48
 * @LastEditTime: 2024-11-12 10:29:07
 */
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import requestConfirm from '@/utils/request-confirm'
import { getApiUrl, serverPrefixAdmin } from '@/utils/region'
import { getEncryptionHex } from '@/utils/encryption.js'
import { showErrorMessage, initSecurityPage } from '@/api/requestError'

const API_URL = getApiUrl()

function http () {
    const service = axios.create({
        baseURL: '/',
        // withCredentials: true, // send cookies when cross-domain requests
        timeout: 30000 // request timeout
    })

    // request interceptor
    service.interceptors.request.use(
        config => {
            config.headers.token = getToken()
            // 配置时间戳签名
            config.headers.signature = getEncryptionHex(Date.now().toString())
            /**
             * @describe 统一处理 formData 传参
             * 前提： post 请求
             * 定义接口时设置 formData: true； 配置 data 字段
             * 参考 \src\api\login.js 的 reqLogin 方法
             */
            if (config.formData) {
                const formData = new FormData()
                Object.keys(config.data).forEach(k => {
                    formData.append(k, config.data[k])
                })
                config.data = formData
            }

            const { apiPrefix } = config
            /**
             *
                有 k8s的 /admin/cloud/
                物模型接口 /tsl/
                固件获取版本 /cloud-api
                普通接口 /cloud/
             */
            /* 统一加特定的前缀 */
            if (apiPrefix === '/tsl') {
                config.baseURL = `${API_URL}${apiPrefix}`
            } else {
                if (!!process.env.VUE_APP_BASE_API_NEED_ADMIN || serverPrefixAdmin()) {
                    config.baseURL = `${API_URL}/admin/cloud`
                } else {
                    config.baseURL = `${API_URL}/cloud`
                }
            }
            return config
        },
        error => {
            console.log(error) // for debug
            return Promise.reject(error)
        }
    )

    service.interceptors.response.use(
        response => {
            const res = response.data
            res.code = res.code || 0

            if (res.code === 0) {
                /* code 不等于0 统一进入错误处理， 业务代码需要单独处理异常 可在catch中捕获具体异常code */
                return res
            } else if (res.code === -1002) {
                MessageBox.confirm('Login timeout,Please login again', 'Login Timeout', {
                    confirmButtonText: 'Login Again',
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    closeOnHashChange: false,
                    showCancelButton: false,
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
                return Promise.reject(res)
            } else if (res.code === -1010) {
                requestConfirm({ content: res.msg, title: 'Remote login', confirmButtonText: 'Login Again' }, () => {
                    store.dispatch('user/FedLogOut').then(() => {
                        window.location.href = window.origin
                    })
                })
                return Promise.reject(res)
            } else {
                // TODO 以下code码待后续处理
                if (res.code === -1023) {
                    initSecurityPage(res)
                } else if (![
                    -2010, -2012, -1011,
                    -3005, -7021, -7022 // -7021,-7022 错误单独处理
                ].includes(res.code)) {
                    showErrorMessage(res)
                }
                return Promise.reject(res)
            }
        },
        error => {
            Message({
                message: error.message,
                type: 'error',
                duration: 1500,
                onClose: () => {
                    const token = getToken()
                    // token 过期
                    if (!token) {
                        store.dispatch('user/resetToken').then(() => {
                            location.reload()
                        })
                    }
                }
            })
            return Promise.reject(error)
        }
    )

    /* /cloud-api 公共前缀  目前（20231024）只有一个接口在用 */
    const requestCloudApi = function (data) {
        data.apiPrefix = '/cloud-api'
        return service(data)
    }

    // 物模型接口前缀，目前只有物模型相关接口在用
    const requestTslApi = function (data) {
        data.apiPrefix = '/tsl'
        return service(data)
    }

    return { service, requestCloudApi, requestTslApi }
}

const { service, requestCloudApi, requestTslApi } = http()

export {
    requestCloudApi,
    requestTslApi
}
export default service
