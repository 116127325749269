/*
 * @LastEditTime: 2024-11-26 18:00:49
 */

/* 云平台所有域名地址 目前用于 1.判断国内外环境 2.获取接口调用地址 */
const CLOUD_DOMAIN = {
    /* 值为空表示默认用主域名调接口 */
    overseas: {
        'system-test.gl-inet.cn': '', // 测试环境 test
        'system.goodcloud.xyz': '', // 海外生产环境
        'cloud-us-staging.goodcloud.xyz': 'api-cloud-us-staging.goodcloud.xyz',
    },
    domestic: {
        // 开发环境
        'cloud-dev2.gl-inet.cn': '', // 使用主域名
        // 国内测试环境
        'goodcloud-system-admin-staging-cn.gl-inet.cn': {
            // api: 'api-cloud-cn-staging.gl-inet.cn', // 原接口地址
            api: 'api-cn-staging.gl-inet.cn', // 接口地址
            prefixAdmin: true,
        },
        // 国内生产环境
        'system.gl-inet.cn': {
            api: 'api-cloud-cn-production.gl-inet.cn', // 接口地址
            prefixAdmin: true,
        },
    },
}

/* 判断当前服务情况 */
const judgeHostname = () => {
    const hostname = window.location.hostname
    const isEn = Object.hasOwn(CLOUD_DOMAIN.overseas, hostname)
    const isZh = Object.hasOwn(CLOUD_DOMAIN.domestic, hostname)
    const isLocalhost = hostname === 'localhost'
    return {
        isEn, // 国外服务
        isZh, // 国内服务
        isLocalhost, // 本地开发
    }
}

/* 判断是否海外服务器 */
const isOverseasArea = function () {
    const { isEn, isZh, isLocalhost } = judgeHostname()
    if (isEn) {
        return true
    } else if (isZh) {
        return false
    } else if (isLocalhost) {
        return false
    } else {
        return true
    }
}

/* 可选区域 */
const firmwareAreaList = (function () {
    return {
        /* 本地开发 */
        development: [
            { value: 'cn', label: 'cn' }
        ],
        /* 部署开发环境 */
        productionDev: [
            { value: 'cn', label: 'cn' }
        ],
        /* 部署国内生产环境 */
        productionCn: [
            { value: 'cn', label: 'cn' }
        ],
        /* 部署生产环境 */
        production: [
            { value: 'jp', label: 'jp' }, { value: 'us', label: 'us' }, { value: 'eu', label: 'eu' }, { value: 'cn', label: 'cn' }
        ],
    }[process.env.NODE_ENV]
})()

/* 是否需要添加 /admin 前缀 */
const serverPrefixAdmin = () => {
    const prefixAdmin = CLOUD_DOMAIN.overseas[location.hostname] || CLOUD_DOMAIN.domestic[location.hostname]
    return typeof prefixAdmin === 'object' ? prefixAdmin.prefixAdmin : false
}

/* 获取 当前域名对应的api接口地址 */
const getApiUrl = function () {
    const setHttpsPrefix = function (url) {
        url = typeof url === 'string' ? url : url.api
        return url ? `https://${url}` : ''
    }

    const { isZh, isEn, isLocalhost } = judgeHostname()

    if (isZh) {
        /* 国内 */
        return setHttpsPrefix(CLOUD_DOMAIN.domestic[location.hostname])
    } else if (isEn) {
        /* 国外 */
        return setHttpsPrefix(CLOUD_DOMAIN.overseas[location.hostname])
    } else if (isLocalhost) {
        return ''
    } else {
        return ''
    }
}

export {
    firmwareAreaList,
    isOverseasArea,

    getApiUrl,
    serverPrefixAdmin,
}
