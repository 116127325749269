/*
 * @Author: yuanwen
 * @Date: 2024-10-24 09:55:01
 * @LastEditors: yuanwen
 * @LastEditTime: 2024-10-24 10:48:13
 * @FilePath: \gl-system-fronted\src\directive\multiLineEllipsis.js
 * @Description: 多行文本超出隐藏+省略指令
 */
const multiLineEllipsis = {
    inserted (el, binding) {
        const { lines = 3, width = '120px' } = binding.value || {}

        el.style.width = typeof width === 'number' ? `${width}px` : width
        el.style.overflow = 'hidden'
        el.style.textOverflow = 'ellipsis'
        el.style.display = '-webkit-box'
        el.style.webkitBoxOrient = 'vertical'
        el.style.webkitLineClamp = lines

        if (getComputedStyle(el)['-webkit-line-clamp'] === undefined) {
            const lineHeight = parseFloat(getComputedStyle(el).lineHeight)
            const maxHeight = lineHeight * lines
            const text = el.innerText
            const words = text.split(' ')
            let tempText = ''

            el.style.height = `${maxHeight}px` // Set max height
            el.innerText = '' // Clear current text

            for (let i = 0; i < words.length; i++) {
                el.innerText += words[i] + ' '
                if (el.scrollHeight > maxHeight) {
                    el.innerText = tempText.trim() + '...'
                    break
                }
                tempText = el.innerText
            }
        }
    }
}
export default multiLineEllipsis
