// import { version } from 'nprogress'

export default {
    language: {
        toggleLang: 'switch language success'
    },
    route: {
        Dashboard: 'Dashboard',
        Version: 'Version',
        Authority: 'Authority',
        Operation: 'Operation',
        User: 'User',
        Application: 'Application',
        Roles: 'Roles',
        Resource: 'Permissions',
        ServiceMonitoring: 'Service Monitoring',
        DeviceMonitor: 'Device Monitor',
        s2sStatistics: 's2s Statistics',
        Mailbox: 'Mailbox',
        DatabaseBackup: 'Database Backup',
        OnlineListOfUsers: 'Online list of users',
        S2sDeviceList: 's2s device list',
        Model: 'Model',
        ModelResource: 'Model Resource',
        Template: 'Template',
        TemplateList: 'Template List',
        SSLCerticate: 'SSL Certicate',
        Firmware: 'Firmware',
        organization: 'Organizations',
        var: 'VAR',
        PermissionGroup: 'Permission Group',
        permissionGroupName: 'Permission GroupName',
        permissionGroupType: 'Permission GroupType',
        firmwareConfig: 'Firmware Config',
        dataDictionary: 'Data Dictionary',
        dataDictionaryDetail: 'Data',
        Log: 'Log',
        ApplicationServiceLog: 'System Log',
        DeviceLog: 'Device Log',
        ActivityLog: 'Activity Log',
        TracingLog: 'Tracing Log',
        Tsl: 'Tsl Management',
        Production: 'Production',
        Feature: 'Feature',
        columnConfig: 'Column Config',
        deviceEvent: 'Device Event',
        statistics: 'Statistics',
        deviceList: 'Device List',
        messageCenter: 'Message Center',
    },
    navbar: {
        home: 'Home',
        about: 'About',
        logOut: 'Log Out',
        version: 'Version',
        verNum: 'Version {verNum}',
        confirm: 'OK'
    },
    firmware: {
        name: 'Name',
        type: 'Type',
        model: 'Model',
        version: 'Version',
        CompileTime: 'Compile Time',
        device: 'Device',
        upgrade_way: 'Upgrade Way',
        supported_version: 'Supported Version',
        description: 'Description',
        uploadfirmware: 'Upload Firmware',
        modificationfirmware: 'Modification Firmware',
        addFirmware: 'Add firmware',
        url: 'Url',
        customer: 'Customer',
        release: 'Release',
        revoke: 'Revoke',
        editfirmware: 'Edit Firmware',
        viewfirmware: 'View Firmware',
        Undofirmware: 'Undo',
        Published: 'Published',
        Unpublished: 'Unpublished',
        action: 'Action',
        Viewdetail: 'View detail',
        upload: 'Upload',
        fw_type: 'Firmware Type',
        fw_user: 'Firmware User',
        vendor: 'Vendor',
        hash_type: 'Hash Type',
        hash_value: 'Hash Value'
    },
    login: {
        title: 'GL-iNet Admin System',
        login: 'Login',
        usernameValidateError: 'Username validate error',
        passwordValidateError: 'Password validate error',
        noPermission: 'You do not have permission:',
        two_factor_authentication: 'Two-factor authentication',
        enter_the_six_digit_code_from_google_authenticator_app: 'Please enter the one-time password generated by your two-factor authenticator app.',
        trustDevice: 'Remember The Device',
        goBack: 'Go back',
        authenticator: 'The verification code is six digits',
        otherCheckWay: 'Other methods?',
        chooseCheckWay: 'Please select verification method',
        email: 'Email',
        cancel: 'Cancel',
        confirm: 'Confirm',
        sendEmailVerificationCode: 'Send Code',
        emailVerificationCode: 'Email verification code',
        sendEmailCodeSuccessful: 'Send email verification code successful',
        emailCodeValidateError: 'Check email inbox to enter 4 digits verification code'
    },
    dashboard: {
        enable: 'Enable',
        serverDeployment: 'Server Deployment',
        equipmentQuantity: 'equipment quantity',
        numberOfNetworks: 'number of networks',
        internet: 'Internet',
        s2sInternet: 's2s Internet',
        s2sDevice: 's2s Device'
    },
    version: {
        versionNumber: 'VersionNumber',
        update: 'Update',
        enter: 'Please enter the version number'
    },
    user: {
        assignRoles: 'Assign roles',
        noRoleTip: 'The current user has not assigned a role, please check the role assignment you need.',
        roleID: 'Role ID',
        roleUsername: 'Role Name',
        roleDescript: 'Role Description',
        userStatus: 'User Status'

    },
    userInfo: {
        addDevice: 'Add Device',
        deviceID: 'Device ID',
        failure: 'Failure',
        // device list table
        name: 'Name',
        mac: 'MAC',
        version: 'Version',
        type: 'Type',
        group: 'Group',
        description: 'Description',
        model: 'Model',
        ip: 'IP Address',
        status: 'Status',
        updateTime: 'Update Time',
        firmware_path: 'Firmware path',
        data_path: 'Data path',
        onlineTime: 'Online Time',
        offlineTime: 'Offline Time',
        lte_signal: 'LTE Signal',
        no_sim: 'No sim card detected',
        action: 'Actions',
        online: 'Online',
        offline: 'Offline',
        deactivated: 'Deactivated',
        view: 'View',
        // add devices
        host: 'Host',
        caution: 'Caution',
        autoDiscover: 'Auto discover',
        devicesAutoDiscoverDescription: 'Devices in the LAN will be automatically discovered, selected a device to add. DDNS / Device ID on the back of the router.',
        device: 'Device',
        selectDevice: 'Select device',
        inputDDNS: 'Input DDNS / Device ID',
        cancel: 'Cancel',
        confirm: 'Confirm',
        manuallyAdd: 'Manually add',
        devicesManualAddDescription: 'The information need to input below can be found on the back of the router.',
        placeholderMAC: 'Please input MAC address',
        placeholderSN: 'Please input S/N',
        placeholderDDNS: 'Input DDNS / Device ID',
        ddnsValidateError: 'Please input the DDNS / Device ID',
        ddnsLengthValidateError: 'DDNS / Device ID should be 7 characters',
        macValidateError: 'Please input the MAC address',
        macLengthValidateError: 'MAC should be like E4956E411111 or E4:95:6E:41:11:11',
        snValidateError: 'Please input the S/N',
        snLengthValidateError: 'S/N should be 16 characters',
        groupValidateError: 'Please select a group',
        hostValidateError: 'Please select a host',
        deviceValidateError: 'Please select a device',
        addDeviceParam: 'missing parameters',
        addDeviceError_others: 'device added by others',
        Devicehasbeenbound: 'is already bound by the following user.',
        deviceHasBeenBoundBySelf: 'The device is already bound by you!',
        deviceHasBeenBound1: 'The device has been bound',
        deviceHasBeenBoundByAnother: 'The device is already bound by another user.',
        infoIncorrect: 'The infomation(MAC, S/N, DDNS/Device ID) you entered is incorrect, please check the information you entered.',
        refresh: 'Refresh',
        userName: 'User name',
        userEmail: 'User email',
        bindTime: 'Bind time',
        devicesNum: 'Total Devices',
        // add devices by excel
        bulkImport: 'Bulk import',
        chooseExcelFile: 'Choose excel file',
        fileSizeLimitDescription: 'excel file should less than 1MB',
        exampleExcelFile: 'Example excel file',
        bulkImportDescription: 'You can import the devices in bulk via excel file, click to download the example file.',
        bulkImportdescription: 'Download the template and fill in the template, Then choose the excel file you modified to import.',
        bulkImportTemplate: 'Download template',
        chooseFileDescription: 'Then choose the excel file you modified to import.',
        fileLengthValidateError: 'Please select the excel file to import.',
        fileSizeValidateError: 'Excel file size must less than 1MB.',
        addDeviceExcelError: 'excel content error',

        // restart devices
        restartWarning: 'This will restart those selected devices. Continue?',
        addSuccess: 'Add success',
        addFailed: 'Add failed',
        delSuccess: 'Delete success',
        delFailed: 'Delete failed',
        rebootSuccess: 'Reboot success',
        rebootFailed: 'Reboot failed'
    },
    application: {
        newApplication: 'New Application',
        applicationStatus: 'Application Status',
        coding: 'Coding',
        editApplication: 'Edit Application',
        invalidName: 'Please enter a name',
        invalidCoding: 'Please enter the code',
        deleteAppTip: 'Are you sure to delete this application?',
        parent: 'parent',
    },
    role: {
        newRole: 'New Role',
        roleName: 'Role Name',
        roleDescription: 'Role Description',
        varOrg: 'VAR/Organization',
        mainRoleName: 'Main Account',
        selectMainRole: 'Select Main Account',
        resourceAllocation: 'Resource allocation',
        editRole: 'Edit Role',
        permissionAssignment: 'Permission Assignment',
        ownedApplication: 'Owned Application',
        enterKeywordsToFilter: 'Enter keywords to filter',
        loading: 'loading ...',
        invalidName: 'Please enter the role name',
        invalidDescription: 'Please enter a role description',
        deleteTip: 'Are you sure to delete this role?',
        pleaseSelectPermission: 'Please select permissions',
        var: 'VAR',
        org: 'ORG',
        general: 'General'
    },
    resource: {
        newPermission: 'New Permissions',
        addNewPermissionGroup: 'New PermissionGroup',
        editPermissionGroup: 'Edit PermissionGroup',
        menuName: 'Menu name',
        menuURL: 'Menu URL',
        permissionDescription: 'Permission Description',
        catalog: 'Catalog',
        menu: 'Menu',
        button: 'Button',
        editPermissions: 'Edit Permissions',
        resourceName: 'Resource Name',
        ownedApplication: 'Owned Application',
        superiorResources: 'Superior resources',
        resourceURL: 'Resource URL',
        httpMethod: 'HTTP Method',
        selectMenu: 'Select Menu',
        invalidresource: 'Please enter the resource name',
        invalidSelectApplication: 'Please select application',
        invalidResourceURL: 'Please enter the resource url',
        invalidResourceHTTPMethod: 'Please enter the HTTP method',
        resourceTypeOfDirectory: 'The parent resource can only be a directory type!',
        resourceTypeOfMenu: 'The superior resource can only be menu type!',
        deletePermission: 'Are you sure to delete this permission?',
        topMenu: 'Top Menu',
        permissionGroupName: 'Permission GroupName',
        permissionGroupType: 'Type',
        groupName: 'Permission GroupName',
        superiorMenu: 'Superior Menu',
        isOnlyViewer: 'Is Viewer',
    },
    service: {
        serviceName: 'Service Name',
        area: 'Area',
        onlineUsers: 'Online Users',
        operation: 'Operation',
        stop: 'Stop',
        userID: 'User ID',
        key: 'Key',
        kicked: 'Kick out login'
    },
    device: {
        deviceMAC: 'Device MAC',
        usernameOrEmail: 'Username or email',
        mark: 'Mark',
        sync: 'Sync'
    },
    s2s: {
        publicIP: 'Public IP'
    },
    email: {
        alarmEmail: 'Alarm Email',
        turnedOn: 'Turned on',
        closed: 'Closed',
        turnOnNotification: 'Turn on notification',
        closeNotification: 'Close notification',
        confirmClosedEmailNotification: 'Are you sure to close the user\'s mailbox message notification?',
        confirmOpenEmailNotification: 'Are you sure to open the user mailbox message notification?'
    },
    backup: {
        manualBackup: 'Manual backup',
        automaticBackup: 'Automatic backup',
        backingUp: 'Backing up',
        uploading: 'Uploading',
        batchId: 'Batch ID',
        fileName: 'File Name'
    },
    model: {
        codeName: 'Code Name',
        newModel: 'New Model',
        editModel: 'Edit Model',
        invalidName: 'Please enter the name',
        invalidCode: 'Please enter the code name',
        deleteTip: 'Are you sure to delete this model?',
        version: 'Version',
        invalidVersion: 'Please enter the version',
        beUpeLev: 'The upper-level resource can only be a tab type',
        deleteresource: 'Are you sure to delete source?'

    },
    template: {
        newTemplate: 'New Template',
        clone: 'Clone',
        deleteWarning: 'This will permanently delete the template your selected. Continue?',
        add: 'Add',
        paramPreviewResolve: 'Configuration Preview',
        enable: 'Enable',
        changeEnableTemplate: 'Are you sure  to change the enable state of the template? This operation may affect the use of the router. Please enter the password for confirmation.',
        closeEnableTemplateBeforeProceeding: 'An enabling template already exists, please close it before proceeding',
        the_length_cannot_be_than_6_characters: 'The length cannot be less than 6 characters',
        saveTemplatePrompt: 'The selected parameters will be saved as a template',
        editTemplate: 'Edit Template',
        createTemplate: 'Add Template'
    },
    batchSetting: {
        all: 'All',
        basic_configuration: 'Basic Configuration',
        wireless: 'Wireless',
        upgrade: 'Upgrade',
        more_settings: 'More Settings',
        '2g': '2.4G WiFi',
        '5g': '5G WiFi',
        admin_password: 'Admin Password',
        lanIP: 'Lan IP',
        systemTimezone: 'System Timezone',
        language: 'Language',
        image_url: 'Logo Address',
        help_url: 'Help Address',
        customer_name: 'Customer Name',
        hostname: 'Host Name',
        server_name: 'Server Name',
        server_url: 'Server Address',
        '2g_ssid': 'SSID',
        '2g_key': 'Key',
        guest_2g_ssid: 'Guest SSID',
        guest_2g_key: 'Guest Key',
        '5g_ssid': 'SSID',
        '5g_key': 'Key',
        guest_5g_ssid: 'Guest SSID',
        guest_5g_key: 'Guest Key',
        autoupdate_firmware_path: 'Firmware Path',
        autoupdate_enable: 'Auto Update Enable',
        autoupdate_time: 'Auto Update Time',
        password: 'Password',
        lan_ip: 'Lan IP',
        system_timezone: 'System Timezone',
        selectParam: 'Click on the left menu to display the configuration that needs to be modified.',
        noData: 'No Data',
        required: 'Required!',
        invalidLanip: 'Invalid Lan IP',
        invalidGuestKeyLength: 'The length cannot be greater than 32',
        invalidKeyLength: '8-32 characters in length',
        invalidPasswordLength: '5-32 characters in length',
        internet: 'Internet',
        wan_proto: 'Protocol',
        wan_username: 'Username',
        wan_password: 'Password',
        wan_ipaddr: 'IP Address',
        wan_gateway: 'Gateway',
        wan_netmask: 'Netmask',
        wan_dns: 'DNS',
        ovs_mode: 'OVS Mode',
        mode: 'Mode',
        private_ovs: 'Private OVS',
        private: 'Private',
        private_disabled: 'Disabled',
        private_controller: 'Controller',
        private_control_type: 'Type',
        private_control_ipaddr: 'Address',
        private_control_port: 'Port',
        private_wired: 'Wired',
        private_wlans: 'Wlans',
        private_ipaddr: 'IP Address',
        private_netmask: 'Netmask',
        private_dhcp_lease: 'DHCP Lease',
        private_datapath_id: 'Datapath ID',
        private_datapath_desc: 'Datapath Desc',
        private_ssl: 'SSL',
        private_ssl_id: 'SSL Certificate',
        guest_ovs: 'Guest OVS',
        guest_disabled: 'Disabled',
        guest_ipaddr: 'IP Address',
        guest_netmask: 'Netmask',
        guest_dhcp_lease: 'DHCP Lease',
        guest_datapath_id: 'Datapath ID',
        guest_datapath_desc: 'Datapath Description',
        guest_wired: 'Wired',
        guest_wlans: 'Wlans',
        guest_controller: 'Controller',
        guest_control_type: 'Type',
        guest_control_ipaddr: 'Address',
        guest_control_port: 'Port',
        guest_ssl: 'SSL',
        guest_ssl_id: 'SSL Certificate',
        guest: 'Guest',
        invaildNetmask: 'Invaild Netmask',
        invaildDatapathID: 'Invaild Datapath ID',
        invaildDNS: 'Invalid DNS',
        invaildPort: 'Invalid Port',
        invaildTime: 'The value must be greater than zero',
        validateWifissidEmpty: 'Cannot be empty',
        validateWifissidErrorLength: 'The ssid\'s length cannot be greater than 32',
        invaildGuestIP: 'Invalid Guest IP address',
        open: 'OPEN',
        close: 'ClOSE',
        ovs_ssl: 'OVS SSL',
        ssl_id: 'SSL'
    },
    ssl: {
        ssl_list: 'SSL List',
        cacert: 'Cacert',
        cert: 'Cert',
        privkey: 'Privkey',
        select_file: 'Select File',
        add_ssl_certificate: 'Add SSL Certificate',
        deleteSLL: 'This will permanently delete the certificate. Continue?',
        the_length_cannot_be_greater_then_128: 'The length cannot be greater then 128',
        create: 'Create'
    }

}
