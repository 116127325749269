/*
 * @Description: 公共部分
 * @Author: warren
 * @LastEditors: lijiaxin
 * @Date: 2023-04-21 09:32:54
 * @LastEditTime: 2024-01-08 11:56:53
 */
export default {
    common: {
        password: 'Password',
        username: 'Username',
        success: 'Success',
        error: 'Error',
        online: 'Online',
        offline: 'Offline',
        total: 'Total',
        device: 'Device',
        user: 'User',
        onlineDevice: 'Online Device',
        offlineDevice: 'Offline Device',
        inactiveDevice: 'Inactive Device',
        email: 'Email',
        phone: 'Phone',
        role: 'Role',
        search: 'Search',
        status: 'Status',
        company: 'Company',
        lastLoginTime: 'Last Login Time',
        action: 'Action',
        enable: 'Enable',
        disenable: 'Disenable',
        description: 'Description',
        effective: 'Effective',
        invalid: 'Invalid',
        cancel: 'Cancel',
        apply: 'Apply',
        confirm: 'Confirm',
        prompt: 'Prompt',
        name: 'Name',
        createTime: 'Create Time',
        updateTime: 'Update Time',
        edit: 'Edit',
        delete: 'Delete',
        warning: 'Warning',
        select: 'Select',
        type: 'Type',
        refresh: 'Refresh',
        details: 'Details',
        deviceID: 'Device ID',
        MAC: 'MAC',
        model: 'Model',
        version: 'Version',
        groupName: 'Group name',
        onlineTime: 'Online Time',
        offlineTime: 'Offline time',
        bindTime: 'Bind time',
        server: 'Server',
        IPAddress: 'IP Address',
        port: 'Port',
        lanIP: 'LAN IP',
        complete: 'Completed',
        fail: 'Failed',
        view: 'View',
        modify: 'Modify',
        reset: 'Reset',
        ok: 'OK',
        SSID: 'SSID',
        selectLength: 'Please select a piece of data',
        add: 'Add',
        next: 'Next',
        back: 'Back',
        actionSuccess: 'Operation succeeded',
        userNotExist: 'User does not exist',
        inviteConfirm: 'Invite',
        inviteEmailTips: 'The email is not registered, whether to invite the user to register?',
        invitePhoneTips: 'The phone is not registered, whether to invite the user to register?',
        findUserError: 'Failed to find user information',
        deleteTips: 'Are you sure you want to delete this item?',
        deleteSuccess: 'Delete Success',
        dispatch: 'Dispatch Permission',
        copy: 'Copy',
        filter: 'Filter',
        noData: 'No data',
        yes: 'Yes',
        no: 'No',
        sync: 'Sync',
        async: 'Async',
        preview: 'Preview',
        clear: 'Clear',
        backPage: 'Back',
        config: 'Config',
        infoRequired: 'Information cannot be empty.',
        executed: 'Executed',
    },
}
