/*
 * @Author: yuanwen
 * @Date: 2024-10-24 10:20:37
 * @LastEditors: yuanwen
 * @LastEditTime: 2024-10-31 18:23:34
 * @FilePath: \gl-system-fronted\src\directive\index.js
 * @Description: 指令注册
 */
import Vue from 'vue'
import multiLineEllipsis from './multiLineEllipsis'
const directives = {
    multiLineEllipsis,
}

const kes = Object.keys(directives)

if (kes.length) {
    kes.forEach(key => {
        Vue.directive(key, directives[key])
    })
}
