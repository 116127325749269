/*
 * @Author: shufei.han
 * @Date: 2024-06-12 14:42:52
 * @LastEditors: shufei.han
 * @LastEditTime: 2024-06-24 17:44:36
 * @FilePath: /gl-system-frontend/src/lang/dataDict/en.js
 * @Description: 数据字典模块翻译文件
 */
export default {
    dataDict: {
        enabled: 'Enabled',
        disabled: 'Disabled',
        enable: 'Enable',
        disable: 'Disable',
        selectStatusTip: 'Please select status',
        data: 'Data',
        startDate: 'Start Date',
        endDate: 'End Date',
        searchTip: 'Please type name or type',
        name: 'Name',
        type: 'Type',
        status: 'Status',
        remark: 'Remark',
        createTime: 'Created Time',
        addDataDict: 'Add Data Dictionary',
        editDataDict: 'Edit Data Dictionary',
        nameTip: 'Input data dictionary name',
        typeTip: 'Select data dictionary type',
        remarkTip: 'Input remark',
        statusTip: 'Select status',
        disableTip: 'Are you sure you want to disable {name}?',
        label: 'Label',
        key: 'Key',
        sort: 'Sort',
        modifySuccess: 'Update success',
        addSuccess: 'Add success',
        searchLabelTip: 'Input label',
        addDataDictData: 'Add Data Dictionary Data',
        editDataDictData: 'Edit Data Dictionary Data',
        labelTip: 'Input label',
        keyTip: 'Input key',
        sortTip: 'Input sort',
        deleteTip: 'Are you sure you want to delete {name}?'
    }
}
