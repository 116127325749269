/*
 * @Author: Warren
 * @Date: 2024-11-08 19:12:27
 * @LastEditors: Warren
 * @LastEditTime: 2024-11-11 11:50:25
 * @FilePath: \gl-system-fronted\src\store\modules\settings.js
 * @Description: 请填写文件说明
 */
import defaultSettings from '@/settings'

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings

const state = {
    showSettings: showSettings,
    fixedHeader: fixedHeader,
    sidebarLogo: sidebarLogo
}

const mutations = {
    CHANGE_SETTING: (state, { key, value }) => {
        if (Object.hasOwn(state, key)) {
            state[key] = value
        }
    }
}

const actions = {
    changeSetting ({ commit }, data) {
        commit('CHANGE_SETTING', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
