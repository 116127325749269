/*
 * @Description:
 * @Author: warren
 * @LastEditors: yuanwen
 * @Date: 2023-04-12 14:57:23
 * @LastEditTime: 2024-10-22 14:22:02
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'

import commonEn from '@/lang/common/en'
import commonZh from '@/lang/common/zh'

import varEn from '@/lang/var/en'
import varZh from '@/lang/var/zh'

import validateEn from '@/lang/validate/en'
import validateZh from '@/lang/validate/zh'

import organizationsEn from '@/lang/organizations/en'
import organizationsZh from '@/lang/organizations/zh'

import firmwareConfigEn from '@/lang/firmwareConfig/en'
import firmwareConfigZh from '@/lang/firmwareConfig/zh'

import dataDictEn from '@/lang/dataDict/en'
import dataDictZh from '@/lang/dataDict/zh'
import logEn from '@/lang/logs/en'
import logZh from '@/lang/logs/zh'

import tslZh from '@/lang/tsl/zh.js'
import tslEn from '@/lang/tsl/en.js'
import errorCodeEn from '@/lang/errorCode/en'
import errorCodeZh from '@/lang/errorCode/zh'

import messageCenterEn from '@/lang/messageCenter/en'
import messageCenterZh from '@/lang/messageCenter/zh'

Vue.use(VueI18n)

const messages = {
    en: {
        ...enLocale,
        ...commonEn,
        ...elementEnLocale,
        ...varEn,
        ...validateEn,
        ...organizationsEn,
        ...firmwareConfigEn,
        ...dataDictEn,
        ...logEn,
        ...tslEn,
        ...errorCodeEn,
        ...messageCenterEn,
    },
    zh: {
        ...zhLocale,
        ...commonZh,
        ...elementZhLocale,
        ...varZh,
        ...validateZh,
        ...organizationsZh,
        ...firmwareConfigZh,
        ...logZh,
        ...tslZh,
        ...dataDictZh,
        ...errorCodeZh,
        ...messageCenterZh,
    }
}

const i18n = new VueI18n({
    // set locale
    // options: en or zh
    locale: Cookies.get('language') || 'en',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    // set locale messages
    messages
})
/* 获取当前使用的语言 */
export function getCurrentLang () {
    /* 如果设置过，就直接返回 */
    let lang = Cookies.get('language')
    if (!lang) {
        // 目前只有 en 和 zh
        lang = 'en'
    }
    return lang
}
export const t = (key, params = {}) => {
    return i18n.t(key, params)
}

export default i18n
