/**
 * @Author: wangchuan
 * @Date: 2024-05-10 11:54:49
 * @LastEditTime: 2024-10-08 15:49:30
 * @LastEditors: wangchuan
 * @FilePath: \gl-system-frontend\src\lang\logs\zh.js
 * @Description: 日志中文翻译
 */
export default {
    logCommon: {
        filter: '筛选',
        placeholderSelected: '请选择',
        yearMonthDay: '年/月/日',
        export: '导出',
        requestParams: '请求参数',
        responseParams: '响应参数',
        createTime: '创建时间',
        details: '日志详情'
    },
    applicationService: {
        inputSearch: '应用/信息',
        traceId: '链路追踪 ID',
        logLevel: '日志级别',
        flag: '日志标签',
        appName: '应用名称',
        msg: '消息',
        classPath: '类名',
        createTime: '创建时间',
        action: '操作',
        applicationServiceExport: '系统日志',
        stackTrace: '堆栈轨迹',
        logFlag: '日志标签',
        debug: '调试',
        info: '信息',
        warning: '告警',
        error: '错误',
        webhookPush: 'webhookPush'
    },
    deviceLog: {
        createTime: '创建时间',
        inputSearch: 'MAC/绑定信息',
        ddns: '设备 ID',
        mac: 'MAC',
        logType: '日志类别',
        bindName: '绑定信息',
        topic: '主题',
        action: '操作',
        content: '详细内容',
        logFlag: '日志标签',
        deviceSign: '设备上下线',
        deviceMsg: '设备信息',
        deviceAuth: '设备鉴权',
        deviceLogExport: '设备日志导出',
        authSuccess: '验证状态',
        authContent: '验证内容',
        response: '响应',
        msg: '消息',
        succeed: '成功',
        failed: '失败',
        deviceOnline: '设备在线',
        deviceOffline: '设备离线',
        msgType: '消息类型'
    },
    activityLog: {
        traceId: '链路 ID',
        inputSearch: '用户名/邮箱/操作',
        activity: '操作描述',
        status: '请求状态',
        sourceId: '来源',
        method: '请求类型',
        ip: 'IP 地址',
        url: '访问路径',
        duration: '持续时间',
        operator: '操作人员',
        operation: '操作说明',
        createTime: '创建时间',
        action: '操作',
        requestParams: '请求参数',
        response: '响应参数',
        logFlag: '日志标签',
        activityLogExport: '操作日志导出',
        logType: '日志类型',
        logLevel: '日志级别',
        email: '邮箱',
        phone: '电话'
    },
    tracingLog: {
        spanId: 'Span ID',
        inputSearch: '链路 ID',
        systemComponent: '系统组件',
        applicationService: '应用 & 服务',
        service: '服务列表',
        viewLogs: '查看日志'
    },
    // 设备日志高级搜索
    devicesList: {
        advanced: '高级搜索',
        advanced_search: '高级搜索',
        Advanced: '',
        match_all: '匹配全部',
        match_any: '匹配任意',
        clear: '清空',
        apply: '应用',
        cancel: '取消',
        confirm: '确定',
        equal: '等于',
        donot_equals: '不等于',
        is_less_than: '小于',
        is_greater_than: '大于',
        is_less_than_or_equals: '小于等于',
        is_greater_than_or_equals: '大于等于',
        contains: '包含',
        donot_contain: '不包含',
        is_included_in: '属于',
        donot_is_included_in: '不属于',
        addcondition: '添加条件',
        and: '和',
        or: '或',
        name: '名称',
        mac: 'MAC地址',
        version: '版本',
        type: '类型',
        sn: 'S/N',
        imei: 'IMEI',
        description: '备注',
        model: '型号',
        ip: 'IP地址',
        status: '状态',
        advance_search_value: '请输入搜索内容。',
        advance_search_value_selsct: '请选择搜索内容。',
        advance_search_value_repeat: '输入搜索内容存在相同部分。',
        advance_search_value_validate: '搜索内容不能为空。',
        ssid: 'SSID',
        ssid4g: '2.4GHz SSID',
        ssid5g: '5GHz SSID',
        firmware_compile_time: '固件编译时间',
        networkMode: '网络模式',

    }
}
