/*
 * @Author: yuanwen
 * @Date: 2024-10-22 14:19:38
 * @LastEditors: yuanwen
 * @LastEditTime: 2024-10-29 17:33:45
 * @FilePath: \gl-system-fronted\src\lang\messageCenter\zh.js
 * @Description: 消息中心中文翻译
 */
export default {
    messageCenter: {
        deleteMessage: '数据删除后将无法找回，确认删除吗？',
        messageManagement: '消息管理',
        startDate: '开始日期',
        endDate: '结束日期',
        addMessage: '新增消息',
        modifyMessage: '修改消息',
        allMemberOfPlatform: '平台的所有成员',
        tableTitle: '标题',
        notificationRecipients: '通知收件人',
        messageStatus: '消息状态',
        priority: '优先级',
        owners: '管理员',
        allMembers: '所有成员',
        organization: '组织',
        self: '个人',
        closeMessage: '如果现在退出，则不会保存所有更改。您确定要退出吗？',
        previous: '上一步',
        createAndSend: '创建并发送',
        orgName: '组织名称',
        personalName: '个人名称',
        urgent: '紧急',
        high: '高',
        medium: '中',
        low: '低',
        isSelected: '已选',
        var: 'Var',
        timedDelivery: '定时发送',
        creationDate: '创建日期',
        creator: '创建者',
        messageDetails: '消息详情',
        deliveryTime: '发送时间',
        member: '成员',
        personalUser: '个人用户',
        organizationNumAndType: '<b>{num}</b>个组织的<b>{type}</b>',
        personalUserNumAndType: '<b>{num}</b>位<b>个人用户</b>',
        contentLengthPrompt: '请确保内容长度在{num}个字符以内。',
        pleaseSelectedReceivers: '请选择至少一个收件人',
        deleteMessageTitle: '删除消息',
    }
}
