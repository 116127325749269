<template>
    <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
        <div class="lang-icon-text">
            <svg-icon class-name="international-icon" icon-class="language" />
            <!-- {{currentLang}} -->
            <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
        </div>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh" :disabled="language==='zh'">中文</el-dropdown-item>
            <el-dropdown-item command="en" :disabled="language==='en'">English</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
export default {
    computed: {
        language () {
            return this.$store.getters.language
        },
        currentLang () {
            let item = 'English'
            if (this.language === 'zh') {
                item = '中文'
            } else if (this.language === 'en') {
                item = 'English'
            }
            return item
        }
    },
    methods: {
        handleSetLanguage (lang) {
            this.$i18n.locale = lang
            this.$store.dispatch('app/setLanguage', lang)
            this.$message({
                message: this.$t('language.toggleLang'),
                type: 'success'
            })
        }
    }
}
</script>

<style scoped>
.international-icon {
  font-size: 20px;
  cursor: pointer;
  vertical-align: -5px!important;
}
.lang-icon-text:hover {
  color: #00838f;
  cursor: pointer;
}
</style>
