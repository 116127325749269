export default {
    language: {
        toggleLang: '语言切换成功'
    },
    route: {
        Dashboard: '首页',
        Version: '版本管理',
        Authority: '权限管理',
        Operation: '运维管理',
        User: '用户管理',
        Application: '应用管理',
        Roles: '角色管理',
        Resource: '权限管理',
        ServiceMonitoring: '服务监控',
        DeviceMonitor: '设备监控',
        s2sStatistics: 's2s统计',
        Mailbox: '邮箱管理',
        DatabaseBackup: '数据库备份',
        OnlineListOfUsers: '在线用户列表',
        S2sDeviceList: 's2s设备列表',
        Model: '型号管理',
        ModelResource: '型号资源',
        Template: '模板管理',
        TemplateList: '模板列表',
        SSLCerticate: 'SSL证书',
        Firmware: '固件管理',
        organization: '组织管理',
        var: 'VAR管理',
        PermissionGroup: '权限组管理',
        firmwareConfig: '固件配置',
        dataDictionary: '数据字典',
        dataDictionaryDetail: '数据',
        Log: '日志',
        ApplicationServiceLog: '应用服务日志',
        DeviceLog: '设备日志',
        ActivityLog: '操作日志',
        TracingLog: '日志追溯',
        Tsl: '物模型管理',
        Production: '产品管理',
        Feature: 'Feature管理',
        columnConfig: '基础数据配置',
        deviceEvent: '设备事件列表',
        statistics: '统计数据配置',
        deviceList: '设备列表',
        messageCenter: '消息中心',
    },
    navbar: {
        home: 'Home',
        about: '关于',
        logOut: '登出',
        version: '版本',
        verNum: '版本号为 {verNum}',
        confirm: '确定'
    },
    firmware: {
        name: '名称',
        type: '类型',
        model: '型号',
        version: '版本',
        CompileTime: '编译时间',
        device: '设备',
        upgrade_way: '更新方式',
        supported_version: '支持版本',
        description: '备注',
        uploadfirmware: '固件编辑',
        modificationfirmware: '固件修改',
        addFirmware: '添加固件',
        url: 'Url',
        customer: '版本类型',
        release: '上传',
        revoke: '撤销',
        editfirmware: '固件编辑',
        viewfirmware: '查看固件',
        Undofirmware: '取消',
        Published: '发布',
        Unpublished: '未发布',
        action: '操作',
        Viewdetail: '查看详情',
        upload: '添加固件',
        fw_type: '固件类型',
        fw_user: '用户',
        vendor: '供应商',
        hash_type: '哈希类型',
        hash_value: '哈希值',
        mac: 'MAC地址',
        ssid: 'SSID',
        searchbar_ip: 'IP地址'
    },
    login: {
        title: 'GL-iNet 后台管理系统',
        login: '登录',
        usernameValidateError: '用户名格式错误',
        passwordValidateError: '密码格式错误',
        noPermission: '你没有权限:',
        two_factor_authentication: '两步验证',
        enter_the_six_digit_code_from_google_authenticator_app: '请输入您的双因素身份验证器应用程序生成的一次性密码。',
        trustDevice: '记住设备',
        goBack: '返回',
        authenticator: '验证码为六位数字',
        otherCheckWay: '其他方式？',
        chooseCheckWay: '请选择验证方式',
        email: '邮箱',
        cancel: '取消',
        confirm: '确认',
        sendEmailVerificationCode: '发送邮箱验证码',
        emailVerificationCode: '邮箱验证码',
        sendEmailCodeSuccessful: '已发送邮件验证码',
        emailCodeValidateError: '请查收邮件并输入邮箱4位验证码'
    },
    dashboard: {
        enable: '启用',
        serverDeployment: '服务器部署情况',
        equipmentQuantity: '设备数量',
        numberOfNetworks: '网路数量',
        internet: '网络',
        s2sInternet: 's2s网络',
        s2sDevice: 's2s设备'
    },
    version: {
        versionNumber: '版本号',
        update: '更新',
        enter: '请输入版本号'
    },
    user: {
        assignRoles: '分配角色',
        noRoleTip: '当前用户没有分配角色，可勾选需要的角色分配',
        roleID: '角色ID',
        roleUsername: '角色名称',
        roleDescript: '角色描述',
        userStatus: '用户状态'
    },
    userInfo: {
        addDevice: '添加设备',
        deviceID: '设备ID',
        failure: '失败',

        // device list table
        name: '名称',
        mac: 'MAC地址',
        version: '版本',
        type: '类型',
        group: '分组',
        description: '备注',
        model: '型号',
        ip: 'IP地址',
        status: '状态',
        updateTime: '更新时间',
        firmware_path: '固件升级地址',
        data_path: '数据服务器路径',
        onlineTime: '最近上线时间',
        offlineTime: '最近离线时间',
        lte_signal: 'LTE 信号',
        no_sim: '未检测到 Sim 卡',
        action: '操作',
        online: '在线',
        offline: '离线',
        deactivated: '未激活',
        view: '查看',
        // add devices
        host: '服务器',
        caution: '警告',
        autoDiscover: '自动发现',
        devicesAutoDiscoverDescription: '局域网内的设备会被自动发现，选择一个设备去添加。DDNS 和 设备ID 可以在设备的背面找到。',
        device: '设备',
        selectDevice: '请选择设备',
        inputDDNS: '请输入 DDNS / 设备ID',
        cancel: '取消',
        confirm: '确定',
        manuallyAdd: '手动添加',
        devicesManualAddDescription: '下面需要填的信息可以在设备的背面找到。',
        placeholderMAC: '请输入 MAC 地址',
        placeholderSN: '请输入序列号S/N',
        placeholderDDNS: '请输入 DDNS / 设备ID',
        ddnsValidateError: '请输入 DDNS / 设备ID',
        ddnsLengthValidateError: 'DDNS / 设备ID 是有7个字符',
        macValidateError: '请输入 MAC 地址',
        macLengthValidateError: 'MAC 地址是格式为 E4956E411111 或 E4:95:6E:41:11:11',
        snValidateError: '请输入序列号 S/N',
        snLengthValidateError: '序列号 S/N 是16个字符',
        groupValidateError: '请选择分组',
        hostValidateError: '请选择服务器',
        deviceValidateError: '请选择一个设备',
        addDeviceParam: '参数丢失',
        addDeviceError_others: '该设备已经被添加进了其他分组',
        Devicehasbeenbound: '已经被其它用户绑定。',
        deviceHasBeenBoundBySelf: '你已经绑定了该设备！',
        deviceHasBeenBoundByAnother: '该设备已经被其他用户绑定。',
        deviceHasBeenBound1: '该设备已经被绑定',
        infoIncorrect: '您输入的信息(MAC, S/N, DDNS/设备ID)不正确，请检查您输入的信息',
        refresh: '刷新',
        userName: '用户名',
        userEmail: '用户邮箱',
        bindTime: '绑定时间',
        // add devices by excel
        bulkImport: '批量导入',
        chooseExcelFile: '选择 excel 文件',
        fileSizeLimitDescription: 'excel 文件须小于1MB',
        exampleExcelFile: '样例 excel 文件',
        bulkImportDescription: '你可以通过 excel 文件来批量导入设备，点击下载样例文件。',
        bulkImportdescription: '下载模板并填写模板, 然后选择已编辑过的 excel 文件导入。',
        bulkImportTemplate: '下载模板',
        chooseFileDescription: '然后选择已编辑过的 excel 文件导入。',
        fileLengthValidateError: '请选择 excel 文件导入',
        fileSizeValidateError: 'excel 文件必须小于1MB',
        devicesNum: '总设备数',
        addDeviceExcelError: 'excel内容错误',
        // restart devices
        restartWarning: '点击确定将会重启你选择的设备，是否继续？',
        addSuccess: '添加成功',
        addFailed: '添加失败',
        delSuccess: '删除成功',
        delFailed: '删除失败',
        rebootSuccess: '重启成功',
        rebootFailed: '重启失败'

    },
    application: {
        newApplication: '新增应用',
        applicationStatus: '应用状态',
        coding: '编码',
        editApplication: '编辑应用',
        invalidName: '请输入名称',
        invalidCoding: '请输入编码',
        deleteAppTip: '你确定要删除此应用吗?',
        parent: '父级应用',
    },
    role: {
        newRole: '新增角色',
        roleName: '角色名称',
        roleDescription: '角色描述',
        varOrg: 'VAR/组织',
        mainRoleName: '主账号',
        selectMainRole: '选择主账号',
        resourceAllocation: '分配资源',
        editRole: '编辑角色',
        permissionAssignment: '权限分配',
        ownedApplication: '所属应用',
        enterKeywordsToFilter: '输入关键字进行过滤',
        loading: '拼命加载中 ...',
        invalidName: '请输入角色名称',
        invalidDescription: '请输入角色描述',
        deleteTip: '确认删除该角色?',
        pleaseSelectPermission: '请选择权限',
        var: 'VAR',
        org: '组织',
        general: '通用'
    },
    resource: {
        newPermission: '新增权限',
        addNewPermissionGroup: '新增权限组',
        editPermissionGroup: '编辑权限组',
        menuName: '菜单名称',
        menuURL: '菜单URL',
        permissionDescription: '权限描述',
        catalog: '目录',
        menu: '菜单',
        button: '按钮',
        editPermissions: '编辑权限',
        resourceName: '资源名称',
        ownedApplication: '所属应用',
        superiorResources: '上级资源',
        resourceURL: '资源URL',
        httpMethod: 'HTTP方法',
        selectMenu: '选择菜单',
        invalidresource: '请输入资源名称',
        invalidSelectApplication: '请选择应用',
        invalidResourceURL: '请输入资源url',
        invalidResourceHTTPMethos: '请输入Http方法',
        resourceTypeOfDirectory: '上级资源只能是目录类型!',
        resourceTypeOfMenu: '上级资源只能是菜单类型!',
        deletePermission: '你确定要删除此权限吗?',
        topMenu: '顶级菜单',
        permissionGroupName: '权限组名称',
        permissionGroupType: '权限组类型',
        groupName: '权限组名称',
        superiorMenu: '上级菜单',
        isOnlyViewer: '是否为View权限',
    },
    service: {
        serviceName: '服务名称',
        area: '地区',
        onlineUsers: '在线人数',
        operation: '运行中',
        stop: '停用',
        userID: '用户ID',
        key: '密钥',
        kicked: '踢出登录'
    },
    device: {
        deviceMAC: '设备MAC',
        usernameOrEmail: '用户名或邮箱',
        mark: '标记',
        sync: '同步'
    },
    s2s: {
        publicIP: '公网IP'
    },
    email: {
        alarmEmail: '告警邮箱',
        turnedOn: '已开启',
        closed: '已关闭',
        turnOnNotification: '开启通知',
        closeNotification: '关闭通知',
        confirmClosedEmailNotification: '确定关闭该用户邮箱消息通知？',
        confirmOpenEmailNotification: '确定打开该用户邮箱消息通知？'
    },
    backup: {
        manualBackup: '手动备份',
        automaticBackup: '自动备份',
        backingUp: '备份中',
        uploading: '上传中',
        batchId: '批次ID',
        fileName: '文件名'
    },
    model: {
        codeName: '编码名称',
        newModel: '新增型号',
        editModel: '编辑型号',
        invalidName: '请输入名称',
        invalidCode: '请输入编码名称',
        deleteTip: '确认删除该型号?',
        version: '版本',
        invalidVersion: '请输入版本号',
        beUpeLev: '上级资源只能是选项卡类型',
        deleteresource: '你确定要删除该权限吗？'

    },
    template: {
        newTemplate: '新增模板',
        clone: '克隆',
        deleteWarning: '你选择的模板将被删除，是否继续？',
        add: '添加',
        paramPreviewResolve: '配置预览',
        enable: '使能',
        changeEnableTemplate: '你确定要更改模板的使能状态吗? 该操作可能会影响路由器的使用。请输入密码用于确认',
        closeEnableTemplateBeforeProceeding: '已存在使能模板，请先关闭再进行操作',
        the_length_cannot_be_than_6_characters: '长度不能小于6个字符',
        saveTemplatePrompt: '选中的参数将保存为模板',
        editTemplate: '编辑模板',
        createTemplate: '添加模板'
    },
    batchSetting: {
        all: '所有',
        basic_configuration: '基础配置',
        wireless: '无线',
        upgrade: '升级',
        more_settings: '更多设置',
        '2g': '2.4G无线网络',
        '5g': '5G无线网络',
        admin_password: '管理员密码',
        lanIP: '局域网IP',
        systemTimezone: '时区',
        language: '语言',
        image_url: 'Logo下载地址',
        help_url: '帮助链接地址',
        customer_name: '用户名',
        hostname: '主机名',
        server_name: '云端服务器名称',
        server_url: '云端服务器地址',
        '2g_ssid': '无线网络名称(SSID)',
        '2g_key': '无线网络密码',
        guest_2g_ssid: '访客无线网络名称(SSID)',
        guest_2g_key: '访客无线网络密码',
        '5g_ssid': '无线网络名称(SSID)',
        '5g_key': '无线网络密码',
        guest_5g_ssid: '访客无线网络名称(SSID)',
        guest_5g_key: '访客无线网络密码',
        autoupdate_firmware_path: '固件下载地址',
        autoupdate_enable: '启用自动升级',
        autoupdate_time: '自动升级时间',
        password: '密码',
        lan_ip: '局域网IP',
        system_timezone: '系统时区',
        selectParam: '单击左侧菜单以显示需要修改的配置。',
        noData: '没有数据',
        required: '必填!',
        invalidLanip: '局域网IP错误',
        invalidGuestKeyLength: '长度不能大于32个字符',
        invalidKeyLength: '长度为8-32个字符',
        invalidPasswordLength: '长度为5-32个字符',
        internet: '网络',
        wan_proto: '协议',
        wan_username: '用户名',
        wan_password: '密码',
        wan_ipaddr: 'IP地址',
        wan_gateway: '网关',
        wan_netmask: '子网掩码',
        wan_dns: 'DNS',
        ovs_mode: 'OVS模式',
        mode: '模式',
        private_ovs: '私人OVS',
        private: '私人',
        private_disabled: '禁用',
        private_controller: '控制器',
        private_control_type: '类型',
        private_control_ipaddr: '地址',
        private_control_port: '端口',
        private_wired: '有线',
        private_wlans: '无线',
        private_ipaddr: 'IP地址',
        private_netmask: '子网掩码',
        private_dhcp_lease: 'DHCP租期',
        private_datapath_id: '数据路径ID',
        private_datapath_desc: '数据路径描述',
        private_ssl: 'SSL',
        private_ssl_id: 'SSL证书',
        guest_ovs: '访客OVS',
        guest_disabled: '禁用',
        guest_ipaddr: 'IP地址',
        guest_netmask: '子网掩码',
        guest_dhcp_lease: 'DHCP租期',
        guest_datapath_id: '数据路径ID',
        guest_datapath_desc: '数据路径描述',
        guest_wired: '有线',
        guest_wlans: '无线',
        guest_controller: '控制器',
        guest_control_type: '类型',
        guest_control_ipaddr: '地址',
        guest_control_port: '端口',
        guest_ssl: 'SSL',
        guest_ssl_id: 'SSL证书',
        guest: '访客',
        invaildNetmask: '无效子网掩码',
        invaildDatapathID: '无效数据路径ID',
        invaildDNS: '无效DNS',
        invaildPort: '无效端口',
        invaildTime: '值必须大于0',
        validateWifissidEmpty: '不能为空',
        validateWifissidErrorLength: 'SSID的长度不能大于32个字符',
        invaildGuestIP: '无效访客IP',
        open: '开启',
        close: '关闭',
        ovs_ssl: 'OVS SSL',
        ssl_id: 'SSL'
    },
    ssl: {
        ssl_list: 'SSL列表',
        cacert: 'Cacert',
        cert: 'Cert',
        privkey: '私钥',
        select_file: '选择文件',
        add_ssl_certificate: '添加SSL证书',
        deleteSLL: '这将永久删除证书。 是否继续？',
        the_length_cannot_be_greater_then_128: '长度不能大于128个字符',
        create: '创建'
    }
}
