<!--
 * @Author: Warren
 * @Date: 2024-11-08 19:12:27
 * @LastEditors: Warren
 * @LastEditTime: 2024-11-11 11:59:18
 * @FilePath: \gl-system-fronted\src\layout\components\Sidebar\Link.vue
 * @Description: 请填写文件说明
-->

<template>
    <!-- eslint-disable -->
    <component v-bind="linkProps(to)">
        <slot />
    </component>
</template>

<script>
import { isExternal } from '@/utils/validate'

export default {
    props: {
        to: {
            type: String,
            required: true
        }
    },
    methods: {
        linkProps (url) {
            if (isExternal(url)) {
                return {
                    is: 'a',
                    href: url,
                    target: '_blank',
                    rel: 'noopener'
                }
            }
            return {
                is: 'router-link',
                to: url
            }
        }
    }
}
</script>
