import request from '@/api/request'

// export function login(data) {
//   return request({
//     url: '/vue-admin-template/user/login',
//     method: 'post',
//     data
//   })
// }

export function getInfo (token) {
    return request({
        url: '/vue-admin-template/user/info',
        method: 'get',
        params: { token }
    })
}

export function logout () {
    return request({
        url: '/logout',
        method: 'post'
    })
}

export function login (data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

export function postTwoFACodeAPI (data) {
    return request({
        url: '/login/2fa/check',
        method: 'post',
        data
    })
}

// 两步验证emial code
export function sendEmailCode (query) {
    return request({
        url: '/login/2fa/send/email',
        method: 'post',
        params: query
    })
}

export function getUserInfo () {
    return request({
        url: '/users',
        method: 'get'
    })
}

export function getUserPermissionList () {
    return request({
        url: '/user/list',
        method: 'get',
        params: {
            limit: 10,
            page: 1,
            username: '',
            email: '',
            phone: '',
            roleid: 0
        }
    })
}

// get user permission
export function getUserPermissionAPI () {
    return request({
        url: '/user/permission',
        method: 'get'
    })
}

// 获取用户信息
export function requestUserInfo () {
    return request({ url: '/v2/user' })
}
