const getters = {
    sidebar: state => state.app.sidebar,
    language: state => state.app.language,
    device: state => state.app.device,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    permission_routers: state => state.permission.sidebarRouters,
    permission_resources: state => state.permission.resources,
    hostList: state => state.user.hostList
}
export default getters
