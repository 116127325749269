/*
 * @Author: yuanwen
 * @Date: 2024-10-22 14:19:38
 * @LastEditors: yuanwen
 * @LastEditTime: 2024-11-01 19:03:14
 * @FilePath: \gl-system-fronted\src\lang\messageCenter\en.js
 * @Description: 消息中心英文翻译
 */
export default {
    messageCenter: {
        deleteMessage: 'Once the data is deleted, it cannot be retrieved. Are you sure you want to delete it?',
        messageManagement: 'Message Management',
        startDate: 'Start Date',
        endDate: 'End Date',
        addMessage: 'Add Message',
        modifyMessage: 'Modify Message',
        allMemberOfPlatform: 'All members of the platform',
        tableTitle: 'Title',
        notificationRecipients: 'Notification Recipients',
        messageStatus: 'Message Status',
        priority: 'Priority',
        owners: 'Owners',
        allMembers: 'All members',
        organization: 'Organization',
        self: 'Self',
        closeMessage: 'If you exit now, all changes will not be saved. Are you sure you want to exit?',
        previous: 'Previous',
        createAndSend: 'Create And Send',
        orgName: 'Organization Name',
        personalName: 'Self Name',
        urgent: 'Urgent',
        high: 'High',
        medium: 'Medium',
        low: 'Low',
        isSelected: 'Selected',
        var: 'Var',
        timedDelivery: 'Timed delivery',
        creationDate: 'Creation Date',
        creator: 'Creator',
        messageDetails: 'Message Details',
        deliveryTime: 'Delivery Time',
        member: 'Member',
        personalUser: 'Personal User',
        organizationNumAndType: '<b>{type}</b> of <b>{num}</b> organizations',
        personalUserNumAndType: '<b>{num}</b> <b>personal users</b>',
        contentLengthPrompt: 'Make sure the content is within {num} characters.',
        pleaseSelectedReceivers: 'Please select a recipient.',
        deleteMessageTitle: 'Removal Message',
    }
}
