/**
 * @Author: wangchuan
 * @Date: 2024-05-10 11:54:40
 * @LastEditTime: 2024-10-08 15:49:47
 * @LastEditors: wangchuan
 * @FilePath: \gl-system-frontend\src\lang\logs\en.js
 * @Description: 日志英文翻译
 */
export default {
    logCommon: {
        filter: 'Filter',
        placeholderSelected: 'Please Selected',
        yearMonthDay: 'Year/Month/Day',
        export: 'Export',
        requestParams: 'Request Params',
        responseParams: 'Response Params',
        createTime: 'Time Stamp',
        details: 'Details'
    },
    applicationService: {
        inputSearch: 'Service/Message',
        traceId: 'Trace ID',
        logLevel: 'Log Level',
        flag: 'Flag',
        appName: 'Application & Service',
        msg: 'Message',
        classPath: 'Class Name',
        createTime: 'Time Stamp',
        action: 'Action',
        applicationServiceExport: 'System Log Export',
        stackTrace: 'Stack Trace',
        logFlag: 'Log Flag',
        debug: 'DEBUG',
        info: 'INFO',
        warning: 'WARNING',
        error: 'ERROR',
        webhookPush: 'WebhookPush'
    },
    deviceLog: {
        createTime: 'Create Time',
        inputSearch: 'MAC/Bind Information',
        ddns: 'Device ID',
        mac: 'MAC',
        logType: 'Log type',
        bindName: 'Bind Information',
        topic: 'Topic',
        action: 'Action',
        content: 'Content',
        deviceLogExport: 'Device Log Export',
        logFlag: 'Log Flag',
        deviceSign: 'Device Online & Offline',
        deviceMsg: 'Device Msg',
        deviceAuth: 'Device Auth',
        authSuccess: 'Auth Succeeded Status',
        authContent: 'Auth Content',
        response: 'Response',
        msg: 'Message',
        succeed: 'Succeeded',
        failed: 'Failed',
        deviceOnline: 'Device Online',
        deviceOffline: 'Device Offline',
        msgType: 'Message Type'
    },
    activityLog: {
        traceId: 'Trace ID',
        inputSearch: 'Username/Email/Operation',
        activity: 'Activity',
        status: 'Activity Status',
        sourceId: 'Activity Portal',
        method: 'Request Method',
        ip: 'IP Address',
        url: 'Access Path',
        duration: 'Request Duration',
        operator: 'Operator',
        operation: 'Operation',
        createTime: 'Time Stamp',
        action: 'Action',
        activityLogExport: 'Activity Log Export',
        requestParams: 'Request Params',
        response: 'Response Params',
        logFlag: 'Log Flag',
        logType: 'Log Type',
        logLevel: 'Log Level',
        email: 'Email',
        phone: 'Phone'
    },
    tracingLog: {
        spanId: 'Span ID',
        inputSearch: 'Trace ID',
        systemComponent: 'System Component',
        applicationService: 'Application & Service',
        service: 'Service',
        viewLogs: 'View Logs'
    },
    // 设备日志高级搜索
    devicesList: {
        advanced: 'Advanced',
        advanced_search: 'Advanced Search',
        match_all: 'Match All',
        match_any: 'Match Any',
        clear: 'Clear',
        apply: 'Apply',
        cancel: 'Cancel',
        confirm: 'Confirm',
        equal: 'is equal to',
        donot_equals: 'is not equal to',
        is_less_than: 'is less than',
        is_greater_than: 'is greater than',
        is_less_than_or_equals: 'is less than or equals',
        is_greater_than_or_equals: 'is greater than or equals',
        contains: 'contains',
        donot_contain: 'doesn\'t contain',
        is_included_in: 'belongs to',
        donot_is_included_in: 'doesn\'t belong to',
        addcondition: 'Add Condition',
        and: 'And',
        or: 'Or',
        name: 'Name',
        mac: 'MAC',
        version: 'Version',
        type: 'Type',
        sn: 'S/N',
        imei: 'IMEI',
        description: 'Description',
        model: 'Model',
        ip: 'IP Address',
        status: 'Status',
        exit_advanced_search: 'Exit Advanced Search',
        advance_search_value: 'Please input the search content.',
        advance_search_value_selsct: 'Please select the search content.',
        advance_search_value_repeat: 'The input search content has the same part.',
        advance_search_value_validate: 'The search content cannot be empty.',
        ssid: 'SSID',
        ssid4g: '2.4GHz SSID',
        ssid5g: '5GHz SSID',
        firmware_compile_time: 'Firmware Compile Time',
        networkMode: 'Network Mode',
    }
}
